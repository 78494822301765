<template>
  <div class="content">
    <vue-whatsapp-widget
      phoneNumber="+524421867547"
      companyName="tulum.party (Gaby)"
      textReply="Typically replies within 10-15 mins (9am - 10pm)"
      companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
    />
    <div class="row" style="background: linear-gradient(black, #2a7178, black, black) ;">
      <div class="text-center col-xl-8 col-sm-12">
        <div style="margin-top: -50px">
          <div class="header text-center" style="margin-bottom: -35px" v-if="!compact">
            <img
              v-lazy="
                this.mainImage+
                '?w=315&h=200&fit=clamp&auto=format'
              "
              style="
              margin-top: 30px;
                box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%);
                border-radius: 0;
              "
              width="315px"
              height="200px"
              alt="tulum epic nights"
            />
            <hr>

            <h1
              class="tulum-party-subtitle-bg"
              style="font-size: 1.4rem; margin-top: 20px"
            >
              Tulum Party Calendar
            </h1>

            <div class="card" style="margin: 10px;padding: 10px">

              <p
              >All Events ● Dinners w/ shows ● Daily Updates ● Clubs Activity ● Spontaneous events ●
                <router-link :to="{path: '/google-calendar-party'}">
                  Sync with any calendar app and/or Phone
                </router-link>
                ● Locations ● Start Times ● Event Details & Tickets ●
                <router-link :to="{path: '/google-calendar-party'}">Google Calendar
                  Version
                </router-link>
                Works
                Offline
              </p
              >
              <br/>
              <br>


            </div>

            <br>
            <img v-lazy="logo+'?w=105&h=15&fit=clamp'" alt="app-logo" width="105px" height="15px"
                 style="border-radius: 0;margin-top: 10px"/>

            <p style="font-weight: lighter;font-size: 11px">DO NOT MISS A THING !</p>
            <br>
            <div
              class="card text-center centered"
              style="
          background: linear-gradient(#303030, black);
            max-width: 500px;
            z-index: 1;
            width: 80%;
            margin-bottom: 50px;
            padding: 10px;
          "
            >


              <p class="section-header">PARTIES, EVENTS & SHOWS CALENDAR</p>


              <div class="card"
                   style="margin-top: 10px;
               max-width: 300px;
               margin-left: auto;
               margin-right: auto;
               left: 0;
               right: 0;
               background: linear-gradient(#303030, black);
">


                <VMenu
                  :triggers="['click']"
                  :delay="2"
                  :popperHideTriggers="['click']"
                  :hideTriggers="['click']"
                >
                  <small style="margin: 15px;font-weight: bold">SELECT PERIOD > </small>
                  <span class="btn btn-link"
                        style="border: 1px solid #2eafb4;"
                  >{{ periodLabel }} </span>
                  <template #popper>
                    <div class="card card-bg-black">
                  <span @click="getPeriod('today')" class="btn-link"
                  >Today</span
                  >
                      <span
                        @click="getPeriod('tomorrow')"
                        class="btn-link"
                      >Tomorrow</span
                      >
                      <span
                        @click="getPeriod('this_week')"
                        class="btn-link"
                      >This Week</span
                      >
                      <span
                        @click="getPeriod('next_week')"
                        class="btn-link"
                      >Next Week</span
                      >
                      <span
                        @click="getPeriod('this_month')"
                        class="btn-link"
                      >This Month</span
                      >
                      <span @click="getPeriod('next_month')"
                            class="btn-link">
                    Next Month</span>
                      <span
                        @click="goTo('/tulum-new-year-festival-2024')"
                        class="btn-link"
                      >
                    NYE 2025
                  </span>
                      <span
                        @click="goTo('/google-calendar-party')"
                        class="btn-link"
                      >
                    Full Calendar</span
                      >
                      <span
                        @click="goTo('/tulum-dj-schedule')"
                        class="btn-link"
                      >

                    DJ Calendar</span
                      >


                    </div>
                  </template>
                </VMenu>
              </div>

            </div>

          </div>
          <br/>
          <img
            class="centered floating"
            v-lazy="birdsImg + '?w=200&h=100'"
            width="200px"
            height="100px"
          />
          <div class="row row-gutters ">

            <div class="col-sm-4 offset-sm-2 ">
              <small class="badge-dark"><strong>{{ periodLabel }}</strong> </small>
            </div>
            <div class="col-sm-4 offset-sm-2 ">
              <small class="badge-dark"
              >{{ periodParties.length }} event(s) found</small
              >
            </div>

            <div class="col-12 text-center card" v-if="periodParties.length === 0">
              <h4 class="badge-dark" style="margin-top: 90px">No event(s) found</h4>
              <small>Most events are published one or two weeks prior</small>
            </div>

            <div class="row" v-if="listView"
                 style="margin-top: 10px;
             padding: 3px;
             border: 2px solid #1d1d1d;
              background: linear-gradient(#2a737a, black);;
"
            >

              <div
                class="col-md-8 offset-md-2 col-sm-10 offset-sm-1"
                :key="idx"
                style="margin-top: 10px;background: radial-gradient(#303030, black)"
                v-for="(event, idx) in periodParties"
              >
                <div v-if="periodPartiesDates[idx]">
                  <p
                    v-if="['month', 'year'].indexOf(period) === -1"
                    style="
                background-color: #2eafb4;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 0;
              "
                  >
                    {{
                      getDay(event.metadata.date + 'T' + event.metadata.start_time)
                    }}
                  </p>
                  <p
                    v-if="['month', 'year'].indexOf(period) > -1"
                    style="
                background-color: #2eafb4;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 0;
              "
                  >
                    {{
                      getDay3(event.metadata.date + 'T' + event.metadata.start_time)
                    }}
                  </p>
                  <p
                    style="
                color: silver !important;
                font-size: 1.4rem;
                background-color: black;
                font-weight: bold;
              "
                  >
                    {{
                      getDay2(event.metadata.date + 'T' + event.metadata.start_time)
                    }}
                  </p>
                </div>

                <MiniEventCard
                  :event="event"
                  withVenue="true"
                  :key="event.title"
                ></MiniEventCard>
              </div>
            </div>


            <div
              class="lds-dual-ring"
              v-if="this.$store.state.status.loading"
              style="position: fixed; z-index: 9999"
            >
              LOADING
            </div>
          </div>

          <div class="row" style="margin-top: 40px;">

            <div
              class="col-12 text-left card " style="background-color: black;padding: 20px">

                <SmartMenu :links="links" :logo="this.logo" primary-color="black" top-section-name="PLAN AHEAD"
                           cta-text=" 📲 DROP US A LINE">
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <img v-lazy="djs+'?w=180&h=200&fit=clamp'" height="200px" width="180px" class="centered">
                      <br>

                      <p>Need some help planning or executing your plan, <b>drop us a line.</b> <br>Let's dive into the
                        Tulum
                        Party
                        Elite Scene</p>

                      <hr>


                    </div>
                  </div>
                </SmartMenu>
            </div>
          </div>


        </div>

      </div>
      <aside class="card col-4 col-xl-4 d-none d-xl-inline">

        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE"
                   :primary-color="primaryColor"
                   top-section-name="TULUM.PARTY">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <hr>
              <p>
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>
      </aside>

    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MiniEventCard from '@/components/EventCard/MiniEventCard';
import EventVenue from '@/components/EventCard/EventVenue';
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
import {COSMIC_BASE_URL} from "@/common/request";
import DynamicLinks from "@/components/DynamicLinks";
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import SmartMenu from "@/components/SmartMenu/SmartMenu";

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'PartyCalendar',
  data: () => {
    return {
      showPalms: false,
      primaryColor: '#2a7178',
      compact: false,
      showCalendarTab: false,
      showEventsTab: true,
      period: 'week',
      periodLabel: 'this_week',
      logo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
      logito:
        'https://imgix.cosmicjs.com/8c49adc0-c592-11ee-8294-5bdacc8e0a52-astro-dj.jpg',
      djs:
        "https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png",
      periodPartiesDates: [],
      blueEyeImage: 'https://imgix.cosmicjs.com/d81fa300-9a2a-11ee-b34c-99d1d89e42eb-eye-blue.png',
      listView: true,
      periodParties: [],
      calImage:
        'https://imgix.cosmicjs.com/00c12a20-7d7c-11ee-9baf-2960bf73c40e-image.png',
      gCalImg:
        'https://imgix.cosmicjs.com/cdf97730-803e-11ee-9ebe-eb22b0517bf0-image.png',
      mainImage: 'https://imgix.cosmicjs.com/d944c4a0-9cf4-11ee-93ed-8bfbf1aced8f-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/717f22a0-d081-11ee-9ce5-59949019255e-tulum-party-green.jpg',
      birdsImg:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
    };
  },
  metaInfo() {
    return {
      title: `Tulum Parties, Events & Shows Calendar.`,
      link: [
        {
          rel: 'canonical',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`,
        },
        {
          name: 'keywords',
          content: `Tulum Party Calendar. Upcoming Events @ Tulum Mexico, events, calendar, clubs, party agenda, dj agenda, dinner, shows`,
        },
        {
          property: 'og:title',
          content: `Tulum Parties, Events & Shows Calendar.`,
        },
        {
          property: 'og:url',
          href: 'https://www.tulum.party/tulum-party-mexico-event-calendar',
        },
        {
          property: 'og:description',
          content: `Information, Location, Dates and Times for the Top Parties, Events, Shows & Dinners in Tulum & The Riviera Maya`,
        },
        {property: 'og:type', content: 'website'},
      ],
    };
  },
  components: {
    SmartMenu,
    CollapseItem,
    Collapse,
    EventVenue,
    RsvpViaDialog,
    MiniEventCard,
    VueWhatsappWidget,
    DynamicLinks
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.calendar;
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    photos: {
      get() {
        return this.incomingParties.map(x => x.metadata.main_image.imgix_url);
      }
    },
    photoCaptions: {
      get() {
        return this.incomingParties.map(x => x.title + ' - ' + x.metadata.date);
      }
    }
  },
  mounted: function () {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });
    if (this.$route.query.compact) {
      this.compact = true;
    }
    this.getPeriod('this_week', true);
    this.$store.dispatch('getLinks', {slug: 'tulum-party-mexico-event-calendar'});
    this.$store.dispatch('getAllParties').then((_x) => {
      this.getPeriod(this.$route.query.period || 'this_week', true)
    });
    setTimeout(() => {
      this.showPalms = true;
    }, 3000);
  },
  methods: {
    goTo: function goTo(page) {
      this.$router.push({path: page, replace: true})
    },
    onQuickReserveClick() {
      window.ga('send', 'event', 'reservations', 'PartySupport', 'Calendar', 20);
      this.$rollbar.info('PartySupport On Calendar');
    },
    getPeriod: function getPeriod(period, silent) {
      let label = '';
      if (period === 'today') {
        label = 'Today';
        this.getThisPeriod('day', label);
      }
      if (period === 'tomorrow') {
        label = 'Tomorrow';
        this.getNextPeriod('day', label);
      }
      if (period === 'this_week') {
        label = 'This Week';
        this.getThisPeriod('week', label);
      }
      if (period === 'next_week') {
        label = 'Next Week';
        this.getNextPeriod('week', label);
      }

      if (period === 'this_month') {
        label = 'This Month';
        this.getThisPeriod('month', label);
      }
      if (period === 'next_month') {
        label = 'Next Month';
        this.getNextPeriod('month', label);
      }
      if (period === 'this_year') {
        label = 'This Year';
        this.getThisPeriod('year', label);
      }
      if (period === 'nye_2024') {
        label = 'NYE 2024';
        this.getNextPeriod('year', label);
      }
      if (!silent) {
        this.notifyChange('Showing events for ' + label);
        // this.$rollbar.info('Calendar: ' + label);
      }
      this.addHashToLocation(period, this.compact);
    },

    getThisPeriod: function getThisPeriod(unitOfTime, label) {
      let parties = this.incomingParties;

      this.period = unitOfTime;
      this.periodLabel = label;
      this.periodParties = [...parties].filter((x) =>
        this.isIncomingParty(unitOfTime, x)
      );
      let _periodDates = {};
      this.periodPartiesDates = [];
      this.periodParties.forEach((y) => {
        if (_periodDates[y.metadata.date]) {
          this.periodPartiesDates.push(null);
        } else {
          _periodDates[y.metadata.date] = true;
          this.periodPartiesDates.push(y.metadata.date);
        }
      });
    },
    getNextPeriod: function getNextPeriod(unitOfTime, label) {
      let parties = this.incomingParties;
      this.period = unitOfTime;
      this.periodLabel = label;
      if (unitOfTime === 'year') {
        fetch(
          encodeURI(
            `${COSMIC_BASE_URL}&limit=30&props=title,slug,content,metadata.parties.slug,metadata.parties.metadata.main_image,metadata.parties.title,metadata.parties.metadata.start_time,metadata.parties.metadata.date&query={"type":"festivals", "slug": "tulum-new-year-festival-2024" }`
          )
        ).then((response) => {
          response.json().then((r) => {
            this.periodParties = [
              ...r.objects.map((x) => x.metadata.parties)[0],
            ].sort((a, b) => {
              return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
              moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
                ? 1
                : -1;
            });
            let _periodDates = {};
            this.periodPartiesDates = [];
            this.periodParties.forEach((y) => {
              if (_periodDates[y.metadata.date]) {
                this.periodPartiesDates.push(null);
              } else {
                _periodDates[y.metadata.date] = true;
                this.periodPartiesDates.push(y.metadata.date);
              }
            });
          });
        });
      } else {
        this.periodParties = [...parties].filter((x) =>
          this.isIncomingNext(unitOfTime, x)
        );
        let _periodDates = {};
        this.periodPartiesDates = [];
        this.periodParties.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            this.periodPartiesDates.push(null);
          } else {
            _periodDates[y.metadata.date] = true;
            this.periodPartiesDates.push(y.metadata.date);
          }
        });
      }
      this.period = unitOfTime;
    },
    addHashToLocation(params, compact) {
      let path = this.$route.path + '?period=' + encodeURIComponent(params);
      if (compact) {
        path += '&compact=true'
      }
      history.pushState(
        {},
        null,
        path
      );
    },
    notifyChange: function notifyChange(change) {
      this.$notify({
        type: 'primary',
        title: 'Calendar',
        message: `${change}`,
        icon: 'tim-icons icon-bell-55',
      });
    },

    isIncomingParty: function isIncomingParty(unitOfTime, x) {
      return (
        moment()
          .startOf(unitOfTime)
          .isSame(x.metadata.date + 'T' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cancun|pdc|carmen|mujeres/) || !x.metadata.magicEvent || (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },

    isIncomingNext: function isIncomingNext(unitOfTime, x) {
      return (
        moment()
          .add(1, unitOfTime)
          .startOf(unitOfTime)
          .isSame(x.metadata.date + ' ' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cancun|pdc|carmen|mujeres/) || !x.metadata.magicEvent || (x.metadata.magicEvent && x.metadata.show_in_calendar))
      );
    },
    getDay: function getYear(date) {
      return moment(date, true).format('ddd');
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD');
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM');
    },
    googleCalendar() {
      window.ga('send', 'event', 'Event', 'Calendar', 'Google Calendar', 1);
      this.$rollbar.info(`Google Calendar Open`);
    },
  },
};
</script>
